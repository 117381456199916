import React from 'react'
import Banner from '../Components/Banner'
import StakeList from '../Components/StakeList'
import { CCol, CContainer, CRow } from '@coreui/react'
import FAQSection from '../Components/Fqsection'

const Home = () => {
    return (
        <>
            <Banner />
            <StakeList />
            <section className="informationMU mt-5">
                <CContainer>
                    <CRow className="align-items-center">
                        <CCol md="12">
                            <div className="infotxt">
                                <h3>Benefits of Staking HFG Token:</h3>
                                <div className="benefitcontent">
                                    <p>
                                        <span>Enhanced Earnings:</span> Staking HFG Tokens allows
                                        holders to earn additional rewards, increasing their overall
                                        returns.
                                    </p>
                                    <p>
                                        <span> Participation in Games:</span> By staking HFG Tokens,
                                        users gain access to exclusive gaming experiences and
                                        receive HUSD tokens, which can be used to play games,
                                        enhancing their engagement with the platform.
                                    </p>

                                </div>
                            </div>
                        </CCol>
                    </CRow>
                </CContainer>
                <CContainer>
                    <FAQSection />
                </CContainer>
            </section>
        </>
    )
}

export default Home