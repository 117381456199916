import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Pagination } from "@mui/material";
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { shortAddress, toLocale } from '../hooks/useContract';
import { formatEther } from 'viem';
import { dateCon } from '../config/helper';
import LaunchIcon from '@mui/icons-material/Launch';
import { transactionUrl } from '../config';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';



export default function StakeTable(props) {
    const data = props.props
    const navigate = useNavigate()

    const [pagination, setPagination] = useState(0);
    const len = data?.length

    //  Code for pagination 
    const [indexOfLast, setindexOfLast] = useState(10)
    const [indexOfFirst, setindexOfFirst] = useState(0)

    function pagechange(event, value) {
        const currentPage = Number(value)
        const dataPerPage = 10

        const indexOfLastTodo = currentPage * dataPerPage;
        const indexOfFirstTodo = indexOfLastTodo - dataPerPage;

        setindexOfFirst(indexOfFirstTodo)
        setindexOfLast(indexOfLastTodo)
    }
    const pageHandler = () => {
        const PageCount = Math.ceil(len / 10)
        setPagination(PageCount)
    }

    useEffect(() => {
        pageHandler()
    }, [len])



    return (
        <>
            <div className="searchbox flex justify-content-between align-items-center flex-row-reverse">
                <div className="form" ></div>

                <div className="backsidebtn" onClick={() => navigate("/")}>
                    <ArrowBackIcon />
                </div>
            </div>

            <TableContainer component={Paper} sx={{ marginTop: "50px" }}>
                <Table aria-label="collapsible table">
                    <TableHead className='incometittle'>
                        <TableRow>
                            <TableCell >Sr No</TableCell>
                            <TableCell align="center">Stake Amount</TableCell>
                            <TableCell align="center">Date</TableCell>
                            <TableCell align="center">Pool</TableCell>
                            <TableCell align="center">Tx Hash</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data ? data.slice(indexOfFirst, indexOfLast).map((row, i) => (
                            < >
                                <TableRow className='incometable' >
                                    <TableCell component="th" scope="row">
                                        {i + indexOfFirst + 1}
                                    </TableCell>
                                    <TableCell align="center">{toLocale(formatEther(row.amount))} HFG</TableCell>
                                    <TableCell align="center">{dateCon(row.blockTimestamp)}</TableCell>
                                    <TableCell align="center">{(row.pool)} Days</TableCell>
                                    <TableCell align="center"><Link style={{ textDecoration: "none", color: '#fff' }} to={transactionUrl + row?.transactionHash} target="blank" >
                                        <span className='d-flex align-items-center justify-content-center'>
                                            {shortAddress(row.transactionHash)}<LaunchIcon sx={{ width: "16px", marginLeft: "3px", color: "#2c70b3" }} />
                                        </span>
                                    </Link></TableCell>
                                </TableRow>

                            </>
                        )) : <></>}
                    </TableBody>
                </Table>
            </TableContainer>
            {pagination <= 1 ?
                ''
                : <>
                    <Pagination className='myPagination' onChange={pagechange} count={pagination} color="secondary" />
                </>
            }
        </>

    );
}