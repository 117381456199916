import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Outlet } from "react-router-dom";
import bgGIF from "../Assets/images/Hashfair Icons/galaxy.gif";
import roulettewheelanim from '../Assets/images/roulettewheelanim.webp'

const Layout = () => {
  return (
    <>
      <Header />
      {/* <img
        className="bgGIF"
        src={bgGIF}
        alt="bgGIF"
        width="100%"
        height="100%"
      /> */}
      <main>
        <Outlet />
      </main>
      <Footer />
      <div class="web3gamesfix">
   <a href="https://hashfair.games/" target="_blank" title="WEB3 GAMES">
	 <img src={roulettewheelanim} width="50" height="50" alt="web3 games"/>
   </a>
</div>
      {/* <img muted playsinline preload="auto" autoPlay loop className="bgVideoBody">
                <source src={bgVideo} type="video/mp4" />
            </video> */}
      {/* <ReactPlayer
                className='react-player bgVideoBody'
                url={bgVideo}
                width='100%'
                height='100%'
                muted
                loop
                playing="true"
                playsinline="true"
            /> */}
    </>
  );
};

export default Layout;
