import { ethers } from 'ethers'
import { CoinAddress, LockingPeriod, Pool1, defaultNetwork, poolDetails, web3Provider } from '../config'
import StakeCoin from "../abi/StakeCoin.json"
import ERCAbi from "../abi/erc20.json"
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAccount, useNetwork } from "wagmi";
import { useContractInstance } from './useContract'
import { createPublicClient, formatEther, formatUnits, http, parseEther } from 'viem'
import { toast } from "react-hot-toast"
import OracleAbi from "../abi/PoolOracle.json"
import { OracleAddress } from '../config'


const polygonMumbai = {
    id: 80001,
    name: "Polygon Mumbai",
    network: "maticmum",
    nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
    },
    rpcUrls: {
        alchemy: {
            http: ["https://polygon-mumbai.g.alchemy.com/v2"],
            webSocket: ["wss://polygon-mumbai.g.alchemy.com/v2"],
        },
        infura: {
            http: ["https://polygon-mumbai.infura.io/v3"],
            webSocket: ["wss://polygon-mumbai.infura.io/ws/v3"],
        },
        default: {
            http: ["https://polygon-rpc.com/"],
        },
        public: {
            http: ["https://polygon-rpc.com/"],
        },
    },
    blockExplorers: {
        etherscan: {
            name: "PolygonScan",
            url: "https://mumbai.polygonscan.com",
        },
        default: {
            name: "PolygonScan",
            url: "https://mumbai.polygonscan.com",
        },
    },
    contracts: {
        multicall3: {
            address: "0xca11bde05977b3631167028862be2a173976ca11",
            blockCreated: 25770160,
        },
    },
    testnet: true,
};

const polygon = {
    id: 137,
    name: "Polygon",
    network: "matic",
    nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
    },
    rpcUrls: {
        alchemy: {
            http: ["https://polygon-mainnet.g.alchemy.com/v2"],
            webSocket: ["wss://polygon-mainnet.g.alchemy.com/v2"],
        },
        infura: {
            http: ["https://polygon-mainnet.infura.io/v3"],
            webSocket: ["wss://polygon-mainnet.infura.io/ws/v3"],
        },
        default: {
            http: ["https://polygon-rpc.com/"],
        },
        public: {
            http: ["https://polygon-rpc.com/"],
        },
    },
    blockExplorers: {
        etherscan: {
            name: "PolygonScan",
            url: "https://polygonscan.com",
        },
        default: {
            name: "PolygonScan",
            url: "https://polygonscan.com",
        },
    },
    contracts: {
        multicall3: {
            address: "0xca11bde05977b3631167028862be2a173976ca11",
            blockCreated: 25770160,
        },
    },
};

export const Stake = (poolAddress) => {
    const { address } = useAccount()
    const stakeInstance = useContractInstance(poolAddress, StakeCoin, address !== undefined && address !== null)

    const execute = useCallback((amount, refer) => {
        return new Promise(async (resolve, reject) => {
            try {
                let stakeAmount = ethers.utils.parseEther(amount);
                const transaction = await stakeInstance.Stake(stakeAmount,
                    (refer !== "null" ? refer : ethers.constants.AddressZero)
                );

                const loadingToast = await toast.promise(transaction.wait(), {
                    loading: "Staking...",
                    success: "Staked Successfully",
                    error: "Failed to Stake",
                });
                toast.dismiss(loadingToast);
                resolve(true)

            } catch (err) {
                console.warn(err);
                resolve(null)
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
            }
        })
    }, [stakeInstance])

    return useMemo(() => {
        return {
            execute
        }
    }, [execute])


};

export const Approve = (pool) => {
    const { address } = useAccount()
    const Token = useContractInstance(CoinAddress, ERCAbi, address !== undefined && address !== null)

    const execute = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            try {
                const txn = await Token.increaseAllowance(pool, ethers.constants.MaxUint256);
                // resolve(txn.wait)
                const loadingToast = await toast.promise(txn.wait(), {
                    loading: "Approving...",
                    success: "Approved Successfully",
                    error: "Failed to Approve",
                });
                toast.dismiss(loadingToast);

            } catch (err) {
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
                resolve(null)
            }
        })
    }, [Token, pool])

    return useMemo(() => {
        return {
            execute
        }
    }, [execute])


}

export const ClaimCoin = (pool) => {
    const { address } = useAccount()

    const stakeInstance = useContractInstance(pool, StakeCoin, address !== undefined && address !== null)
    const execute = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            try {

                const transaction = await stakeInstance.UserClaimReward();
                // resolve(transaction.wait)
                const loadingToast = await toast.promise(transaction.wait(), {
                    loading: "Claiming...",
                    success: "Claimed Successfully",
                    error: "Failed to Claim",
                });
                toast.dismiss(loadingToast);

            } catch (err) {
                console.error(err);
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
                resolve(null)

            }
        })
    }, [stakeInstance])

    return useMemo(() => {
        return {
            execute
        }
    }, [execute])

};

export const RemoveStake = (pool) => {
    const { address } = useAccount()
    const stakeInstance = useContractInstance(pool, StakeCoin, address !== undefined && address !== null)
    const execute = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            try {

                const transaction = await stakeInstance.RemoveStake();
                const loadingToast = await toast.promise(transaction.wait(), {
                    loading: "Removing...",
                    success: "Removed Successfully",
                    error: "Failed to Remove",
                });
                toast.dismiss(loadingToast);
                // resolve(transaction.wait)

            } catch (err) {
                console.error(err);
                resolve(null)
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)

            }
        })
    }, [stakeInstance])

    return useMemo(() => {
        return {
            execute
        }
    }, [execute])

}


export const GetReward = () => {
    const [Data, setData] = useState(0);
    const [day, setDay] = useState(0);
    const [percent, setPercent] = useState(0);

    // const stakeInstance = useContractInstance(pool, StakeCoin)
    const execute = useCallback((val, pool) => {
        return new Promise(async (resolve, reject) => {
            try {
                const stakeInstance = new ethers.Contract(pool, StakeCoin, web3Provider)
                // const ContractData = await stakeInstance.getPoolValue()
                const amount = parseEther(val)
                let Data = await stakeInstance.getRewardData(amount);
                Data = ethers.utils.formatUnits(Data, 18)
                setData(Data)
                // filter pool Percent and days 
                const poolData = poolDetails.find(item => item.pool.toLowerCase() === pool.toLowerCase())
                setDay(poolData.days)
                setPercent(poolData.percent)
                resolve(true)
            } catch (err) {
                resolve(null)
                console.warn(err);
            }
        })


    }, [])

    return useMemo(() => {
        return {
            execute,
            Data,
            day,
            percent
        }
    }, [execute, day, percent, Data])
}





export function Alldata(pool) {
    const { chain } = useNetwork()
    const isCorrectNetwork = chain?.id === defaultNetwork
    const { address } = useAccount()
    const [allowance, setAllowance] = useState(0);
    const [HfgBalance, setHfgBalance] = useState(0);

    const Token = useContractInstance(CoinAddress, ERCAbi, address !== undefined && address !== null)

    const fetchHFGTokenInfo = useCallback(async () => {
        try {
            const hfgAllowance = await Token.allowance(address, pool)
            const _hfgAllowance = formatUnits(hfgAllowance, 18)
            setAllowance(_hfgAllowance)

            const hfgBalance = await Token.balanceOf(address)
            const _hfgBalance = Number(formatUnits(hfgBalance, 18))
            setHfgBalance(_hfgBalance)


        } catch (err) {
            console.error(err)
        }
    }, [Token, address, pool])


    useEffect(() => {
        const interval = setInterval(async () => {
            if (address && isCorrectNetwork) {
                fetchHFGTokenInfo()
            }

        }, 8000);
        return () => clearInterval(interval);
    }, [fetchHFGTokenInfo, address, isCorrectNetwork, pool]);

    useEffect(() => {
        if (address && isCorrectNetwork) {

            fetchHFGTokenInfo();
        }

    }, [fetchHFGTokenInfo, address, isCorrectNetwork, pool])


    return useMemo(() => {
        return {
            allowance,
            HfgBalance
        }
    }, [allowance,HfgBalance])

}

export function ReferralData() {
    const { chain } = useNetwork()
    const isCorrectNetwork = chain?.id === defaultNetwork

    const { address } = useAccount()
    const [referedBy, setReferedBy] = useState(0);
    const [referals, setReferals] = useState(0);
    const [referalReward, setReferalReward] = useState(0);
    const [canRefer, setCanRefer] = useState(false);

    const client = createPublicClient({
        chain: polygon,
        transport: http(),
    });


    const getUserPoolData = useCallback(async () => {

        try {

            const data = await client.multicall({
                contracts: [
                    {
                        address: Pool1,
                        abi: StakeCoin,
                        functionName: 'getDirectReferal',
                        args: [address],
                    },
                    {
                        address: Pool1,
                        abi: StakeCoin,
                        functionName: 'getReferalLength',
                        args: [address],
                    },
                    {
                        address: Pool1,
                        abi: StakeCoin,
                        functionName: 'getReferalReward',
                        args: [address],
                    },
                    {
                        address: OracleAddress,
                        abi: OracleAbi,
                        functionName: 'getUserReferalData',
                        args: [address],
                    },

                ],
                allowFailure: false
            })

            if (data?.length > 0) {
                if (data[0] === ethers.constants.AddressZero) {
                    setReferedBy(0);
                }
                else {
                    setReferedBy(data[0])
                }
                setReferals(parseFloat(data[1]))
                setReferalReward(formatEther(data[2]))
                setCanRefer(data[3].canRefer)
            }

        } catch (error) {
            console.error(error);
        }

    }, [address, client])

    useEffect(() => {
        if (address && isCorrectNetwork) {
            getUserPoolData()
        }
    }, [address, isCorrectNetwork, getUserPoolData])

    useEffect(() => {
        const interval = setInterval(async () => {
            if (address && isCorrectNetwork) {
                getUserPoolData()
            }

        }, 8000);
        return () => clearInterval(interval);
    }, [getUserPoolData, address, isCorrectNetwork]);

    return useMemo(() => {
        return {
            referalReward,
            referals,
            referedBy,
            canRefer,
        }
    }, [referalReward, referals, referedBy, canRefer])
}



export function UserCalling(pool) {
    const { address } = useAccount()
    const { chain } = useNetwork()
    const isCorrectNetwork = chain?.id === defaultNetwork

    const [Timer, setTimer] = useState(0);
    const [TotalReward, setTotalreward] = useState(0);
    const [day, setDay] = useState(false);
    const [tag, setTag] = useState(0);
    const [claimedReward, setClaimedReward] = useState(0);
    const [claimableReward, setClaimableReward] = useState(0);
    const [stakeAmount, setStakeAmount] = useState(0);

    const client = createPublicClient({
        chain: polygon,
        transport: http(),
    });

    const getUserPoolData = useCallback(async () => {

        try {

            const data = await client.multicall({
                contracts: [
                    {
                        address: pool,
                        abi: StakeCoin,
                        functionName: 'getStakedAmount',
                        args: [address],
                    },
                    {
                        address: pool,
                        abi: StakeCoin,
                        functionName: 'getClaimedReward',
                        args: [address],
                    },
                    {
                        address: pool,
                        abi: StakeCoin,
                        functionName: 'getClaimableReward',
                        args: [address],
                    },
                    {
                        address: pool,
                        abi: StakeCoin,
                        functionName: 'getTotalReward',
                        args: [address],
                    },
                    {
                        address: pool,
                        abi: StakeCoin,
                        functionName: 'getUnStakeTime',
                        args: [address],
                    },
                    {
                        address: pool,
                        abi: StakeCoin,
                        functionName: 'getPoolValue',
                    },
                ],
                allowFailure: false
            })
            

            if (data?.length > 0) {
                setStakeAmount(formatEther(data[0]))
                setClaimedReward(formatEther(data[1]))
                setClaimableReward(formatEther(data[2]))
                setTotalreward(formatEther(data[3]))
                setTimer(parseFloat(data[4]))
                setDay(parseFloat(data[5][0]))
                if ((parseFloat(data[4]) * 1000) > Date.now()) {
                    setTag(0)
                } else {
                    if (((parseFloat(data[4]) + LockingPeriod) * 1000) < Date.now()) {
                        setTag(1)
                    } else {
                        setTag(2)
                    }
                }
            }

        } catch (error) {
            console.error(error);
        }

    }, [address, client, pool])

    useEffect(() => {
        if (address && isCorrectNetwork) {
            getUserPoolData()
        }
    }, [address, isCorrectNetwork, pool, getUserPoolData])

    useEffect(() => {
        const interval = setInterval(async () => {
            if (address && isCorrectNetwork) {
                getUserPoolData()
            }

        }, 8000);
        return () => clearInterval(interval);
    }, [getUserPoolData, address, isCorrectNetwork, pool]);

    return useMemo(() => {
        return {
            stakeAmount,
            claimableReward,
            claimedReward,
            tag,
            Timer,
            day,
            TotalReward

        }
    }, [stakeAmount, claimableReward, claimedReward, tag, Timer, day, TotalReward])
}





