import React, { useCallback, useRef, useState } from "react";
import { toLocale } from "../hooks/useContract";
import { CCol } from "@coreui/react";
import LazyLoad from "react-lazyload";
import gif from "../Assets/images/Hashfair Icons/HFG Coin.gif";

import { Alldata, Approve, GetReward, Stake } from "../hooks/useContractData";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-hot-toast"
import CircularProgress from '@mui/material/CircularProgress';

import DialogTitle from "@mui/material/DialogTitle";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { Pool1, Pool2, Pool3, Pool4, Pool5, Pool6 } from "../config";

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Yourstake = () => {
  const selectRef = useRef(null);
  const select2Ref = useRef(null);
  const location = useLocation();
  const [Staked, setStaked] = useState(false);
  const [amount, setAmount] = useState("");
  const [pool, setPool] = useState(Pool1);
  const [refer, setrefer] = useState("");
  const [load, setLoad] = useState(true);

  // Export Functions
  const { execute: getRewardData, Data, percent, day } = GetReward();
  const { execute: ApproveToken } = Approve(pool);
  const { execute: Staking } = Stake(pool);
  const { allowance,HfgBalance } = Alldata(pool);


  const RewardCallback = useCallback(async () => {
    const selectedPool = selectRef.current?.value;
    const amount = select2Ref.current?.value;
    try {
      setLoad(false)
      const promist = await getRewardData?.(String(amount), selectedPool);
      setLoad(true)
    } catch (err) {
      setLoad(false)
      toast.error(
        err.reason ? err.reason : err.data ? err.data.message : err.message
      );
    }
  }, [getRewardData]);

  const StakeCallback = useCallback(async () => {
    try {
      const amount = select2Ref.current?.value;

      setStaked(true);
      const promist = await Staking?.(String(amount), String(refer));

      setStaked(false);
    } catch (err) {
      setStaked(false);
      toast.error(
        err.reason ? err.reason : err.data ? err.data.message : err.message
      );
    }
  }, [Staking, setStaked,refer]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const ReferralId = queryParams.get("referrer");
    setrefer(ReferralId);
  }, [location.search]);



  return (
    <>
      <CCol
        className="cardsec"
        sm={6}
        md={5}
        lg={4}
        xl={3}
        style={{ margin: "0 auto" }}
      >
        <div className="">
          <a href="#!">
            <LazyLoad
              className="nftsvideo"
              once
              placeholder={
                <svg
                  className="loaderAnim"
                  version="1.1"
                  id="L9"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 100 100"
                  enableBackground="new 0 0 0 0"
                >
                  <path
                    fill="#ffffff"
                    d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                  >
                    <animateTransform
                      attributeName="transform"
                      attributeType="XML"
                      type="rotate"
                      dur="1s"
                      from="0 50 50"
                      to="360 50 50"
                      repeatCount="indefinite"
                    />
                  </path>
                </svg>
              }
            >


              <div className="itembox mt-3">
                <div className="d-flex">
                  <img src={gif} alt="GIF" />
                </div>
              </div>
              <div className="stakebtnarea">
                <>
                  <div className="inputvalue">
                    <div className="position-relative">
                    <input
                      ref={select2Ref}
                      className="value"
                      type="number"
                      placeholder="Enter Amount"
                      value={amount}
                      onChange={(e) => {
                        setAmount(e.target.value);
                        RewardCallback();
                      }}
                    />
                    <span className="maxbtn" onClick={()=>setAmount(HfgBalance)} >Max</span>
                    </div>
                    <select
                      ref={selectRef}
                      class="form-select"
                      aria-label="Default select example"
                      placeholder="Select Pool"
                      onChange={(e) => {
                        setPool(e.target.value);
                        RewardCallback();
                      }}
                    >
                      <option selected value={Pool1}>
                        7 Day Pool
                      </option>
                      <option value={Pool2}>30 Day Pool</option>
                      <option value={Pool3}>60 Day Pool</option>
                      <option value={Pool4}>90 Day Pool</option>
                      <option value={Pool5}>180 Day Pool</option>
                      <option value={Pool6}>360 Day Pool</option>
                    </select>
                    <div className="leftinfo">
                      <span className="labeltag">Avl Balance</span>
                      <span> 
                        {toLocale(HfgBalance)} HFG
                        </span>
                    </div>
                    <div className="leftinfo">
                      <span className="labeltag">APR</span>
                      <span> {load ? <>
                        {percent}%
                      </> : <CircularProgress size={"16px"} />} </span>
                    </div>
                    <div className="leftinfo">
                      <span className="labeltag">Total Days</span>
                      <span> {load ? 
                      <div style={{marginRight:"4px"}}>
                      {day} DAYS
                      </div>
                       : <CircularProgress size={"16px"} />} </span>
                    </div>
                    <div className="leftinfo">
                      <span className="labeltag">Total Reward</span>
                      <span> {load ? 
                      <>
                      {toLocale(Data)} HUSD
                      </>
                       : <CircularProgress size={"16px"} />} </span>
                    </div>
                    <div className="leftinfo">
                      <span className="labeltag">Per Day Rewards</span>
                      <span>
                        {" "}
                        {/* { load ? (Data > 0 && day > 0 ? toLocale(Data / day) : 0):<CircularProgress size={"16px"} />} */}
                        {load ? (Data > 0 && day > 0 ? amount > 0 && Number(toLocale(Data/day)) ===0 ? "less than 0.001":toLocale(Data/day) : 0) : <CircularProgress size={"16px"} />}
                      </span>
                    </div>
                    <div></div>
                  </div>

                  {(allowance > 0 && (
                    <>
                      <button className="boxbtn" onClick={StakeCallback} disabled={Staked}>
                        <span>{Staked ? "Staking..." : "Stake HFG"}</span>
                      </button>
                    </>
                  )) || (
                      <button className="boxbtn" onClick={ApproveToken}>
                        Approve HFG
                      </button>
                    )}
                  <br />
                  <br />
                  <p className=" notearea ">
                    *<b>Note</b> - If you unstake HFG before maturity, 10% of your staked amount will be deducted. Any pending rewards at that time will not be processed. Ensure you wait until maturity to avoid these penalties.
                  </p>
                </>
              </div>
            </LazyLoad>
          </a>
        </div>
      </CCol>
    </>
  );
};

export default Yourstake;
