import React from "react";

const Fqsection = () => {
  return (
    <div className="container mx-auto">
      <div className="top-bar text-center pt-5 pb-5">
        <span className="h1">Frequently asked questions</span>
      </div>

      <div className="pb-3 mt-3">
        <div className="row">
          <div className="col-md-4 mb-4 test">
            <div className="text-center p-3">
              <div className="fqbox">
                <span className="dot">
                  <span>01</span>
                </span>
                <h2 className="h5 pt-3 mb-2">
                  Are there multiple pools for staking HFG?
                </h2>
                <p className="answer">
                  - Yes, various pools are available for different staking
                  durations.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4 test">
            <div className="text-center p-3">
              <div className="fqbox">
                <span className="dot">02</span>
                <h3 className="h5 pt-3 mb-2">
                  What's the penalty for unstaking before maturity?
                </h3>
                <p className="answer">
                  - A 10% deduction will be applied to the staked amount.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4 test">
            <div className="text-center p-3">
              <div className="fqbox">
                <span className="dot">03</span>
                <h4 className="h5 pt-3 mb-2">
                  Is there a lock-in period post-maturity for claimed stakes?
                </h4>
                <p className="answer">
                  - Yes, there's a 24-hour locking period after maturity.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4 test">
            <div className="text-center p-3">
              <div className="fqbox">
                <span className="dot">04</span>
                <h4 className="h5 pt-3 mb-2">How do I choose the best staking pool?</h4>
                <p className="answer">
                  - Evaluate based on your investment goals and the pool's
                  returns.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4 test">
            <div className="text-center p-3">
              <div className="fqbox">
                <span className="dot">05</span>
                <h4 className="h5 pt-3 mb-2">
                  Are there referral bonuses in HFG Staking?
                </h4>
                <p className="answer">
                  - Yes, there are three levels: 1st at 10%, 2nd at 5%, and 3rd
                  at 2%.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4 test">
            <div className="text-center p-3">
              <div className="fqbox">
                <span className="dot">06</span>
                <h4 className="h5 pt-3 mb-2">
                  What tokens do I receive when I stake HFG?
                </h4>
                <p className="answer">
                  - You will be rewarded with HUSD tokens.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fqsection;
