import { providers } from "ethers"
import Web3 from "web3"
import { InjectedConnector } from 'wagmi/connectors/injected'

export const CoinAddress = "0x681E04eA8F903da45A6520eE1e5F4B21b4503fcf";  // Mainnet HFG Token
export const HUSD = "0xc4f58cdce4768d78f36cb3bd9652fcbace94ccd6"  // Mainnet HUSD Token


export const OracleAddress = "0x22e17e451E0CA56C7601a9711BCbe7488dAbE491"

export const Pool1 = "0x42319C5b303B86f08dC6f301290edf32349b8a83"
export const Pool2 = "0xef0AaD57AF88B56F3DB8621EC6566e404de06226"
export const Pool3 = "0x622D3f9c8bc142CF02ACf39f629072cD9141697b"
export const Pool4 = "0x5adb6E853B41A36Ee9Ae9F064795237D8598064C"
export const Pool5 = "0x0558f4187ce35D187ae9fD0393C8C21E1527053A"
export const Pool6 = "0xBe6dCd46bfF956ac346A4cbEC746EcdCf3C41576"


export const LockingPeriod = 86400  // 24hrs

export const graphQlUrl = "https://api.thegraph.com/subgraphs/name/whyusethis/hf-stake-api"

export const transactionUrl = "https://polygonscan.com/tx/"


export const chainRPC = "https://polygon-rpc.com/"  //mainnet

export const defaultWeb3 = new Web3(chainRPC)
export const web3Provider = new providers.JsonRpcProvider(chainRPC);

export const defaultNetwork = 137
export const connector = new InjectedConnector()

export const url = "https://stake.hashfair.io/"
export const polygonchainRPC = "https://polygon-rpc.com"




export const poolDetails = [{ pool: Pool1, percent: 8, days: 7 },
    { pool: Pool2, percent: 12, days: 30 },
    { pool: Pool3, percent: 20, days: 60 },
    { pool: Pool4, percent: 30, days: 90 },
    { pool: Pool5, percent: 48, days: 180 },
    { pool: Pool6, percent: 60, days: 360 }
]


// export const web3Provider = new ethers.providers.JsonRpcProvider(defaultWeb3.currentProvider)

