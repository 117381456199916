import { useCallback, useEffect, useMemo, useState } from "react";
import { graphQlUrl } from "../config";
import axios from "axios";
import { useAccount } from "wagmi";

export const useGraphData = () => {
    const [data, setData] = useState([])
    const [load, setLoad] = useState(0)
    const { address } = useAccount()
    const fetchGraphData = useCallback(async () => {
        try {
            if (address) {

                const options = {
                    method: 'POST',
                    url: graphQlUrl,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: {
                        query: `{
                            tierDatas(where:{referral:"${address}"}  ,orderBy: blockTimestamp,  orderDirection: desc) {
                              id
                              referred
                              pool
                              level
                              amount
                              myClaimed
                              status
                            }
                            
                            transactionHistories(where:{referral:"${address}"}, orderBy: blockTimestamp,  orderDirection: desc) {
                                  referred
                                  pool
                                  level
                                  amount
                                  transactionHash
                                }
                            stakeds(where:{user:"${address}"}, orderBy: blockTimestamp,  orderDirection: desc) {
                                    id
                                    user
                                    amount
                                    pool
                                    transactionHash
                                    blockTimestamp
                                  }
                          }`
                    }
                };
                const response = await axios.request(options);
                const res = response.data.data;
                if ((res.tierDatas).length <= 0 && (res.transactionHistories).length <= 0 && (res.stakeds).length <= 0) {
                    setLoad(1)
                } else (setLoad(0))

                setData(res)
            }
            else {
                setData([])
            }

        } catch (err) {
            console.error(err)
        }
    }, [address, setData])

    useEffect(() => {
        const inter = setInterval(() => {
            fetchGraphData()
        }, 10000);

        return () => clearInterval(inter)
    }, [fetchGraphData, address])

    useEffect(() => {
        fetchGraphData()
    }, [fetchGraphData, address])

    return useMemo(() => {
        return {
            data,
            load
        }
    }, [data, load])
}


