import React from 'react'
import { FaTwitter, FaFacebook, FaReddit, FaYoutube, FaTelegram, FaMedium ,FaDiscord} from 'react-icons/fa';
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        // footer area start
        <footer className="footer_widgets">
            <div className="footer_bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="footer_bottom_inner d-flex justify-content-between align-items-center">
                                <div className="copyright_right">
                                    <p>© 2023 <i className="icofont-heart"></i>  by <a href="/">Has#fair Games</a></p>
                                </div>
                                <div className="footer_bottom_link_menu">
                                    <div className="footer_social">
                                        <ul className="d-flex">
                                            <li><Link to="https://www.facebook.com/hashfairgames" title='Facebook' target="_blank" className="mx-2"><FaFacebook /></Link></li>
                                            <li><Link to="https://twitter.com/Hashfair_Games" title='Twitter' target="_blank" className="mx-2"><FaTwitter /></Link></li>
                                            <li><Link to="https://www.youtube.com/channel/UCt_4P2eqk0Jsn5MNsWapqcA" title='Youtube' target="_blank" className="mx-2"><FaYoutube /></Link></li>
                                            <li><Link to="https://t.me/Hash_Fair_Games" title='Telegram ' target="_blank" className="mx-2"><FaTelegram /></Link></li>
                                            <li><Link to="https://discord.com/invite/yb2UTfUdat" title='Discord ' target="_blank" className="mx-2"><FaDiscord /></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer