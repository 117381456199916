import { CRow, CCol } from '@coreui/react';
import Wallet from '../Assets/images/Hashfair Icons/refferal-wallet.png';
import referral from '../Assets/images/Hashfair Icons/no-of-referral.png';
import link from '../Assets/images/Hashfair Icons/ref link.png';
import { shortAddress, toLocale } from '../hooks/useContract';
import {  ReferralData } from '../hooks/useContractData';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useAccount } from 'wagmi';
import {  url } from '../config';
import { toast } from "react-hot-toast"

import reward from '../Assets/images/Hashfair Icons/refferal-reward.png'


export const Referral = () => {

    const { address } = useAccount()
    // const { referals, referedBy, referalReward, canRefer } = RefData(Pool1);
    const { referals, referedBy, referalReward, canRefer } = ReferralData();
    const notify = () => toast.success('Copied Successfully!')

    return (
        <CCol md="12">
            <CRow xs={{ cols: 1 }} md={{ cols: 3 }} lg={{ cols: 3 }} xl={{ cols: 4 }} className="justify-content-center">
                <CCol sm="auto" className='mb-2'>
                    <div className='inrInfoBx'>
                        <div className='imginrBx'>
                            <img src={link} alt="Referral Link" />
                        </div>
                        <div className="inputvalue">
                            <div className="input-wrapper">
                                <CopyToClipboard text={`${url}?referrer=${address}`}>
                                    <div className="inputbar"  >{canRefer ?
                                        <span onClick={notify}>
                                            {url.substring(0, 16)}
                                            <CopyAllIcon style={{ fontSize: '1.2rem', marginLeft: '3px' }} />
                                        </span> : <span>Not Eligible</span>}
                                    </div>
                                </CopyToClipboard>
                            </div>
                        </div>
                        <p>Referral link</p>
                    </div>
                </CCol>
                <CCol sm="auto" className='mb-2'>
                    <div className='inrInfoBx'>
                        <div className='imginrBx'>
                            <img src={referral} alt="No. of Referrals" />
                        </div>
                        <strong>
                            {toLocale(referals)}
                        </strong>
                        <p>No. Of referral</p>
                    </div>
                </CCol>
                <CCol sm="auto" className='mb-2'>
                    <div className='inrInfoBx'>
                        <div className='imginrBx'>
                            <img src={Wallet} alt="Referral Wallet" />
                        </div>
                        <strong>
                            {shortAddress(referedBy !== 0 ? referedBy : "")}
                        </strong>
                        <p>Referral Wallet</p>
                    </div>
                </CCol>
                <CCol sm="auto" className='mb-2'>
                    <div className='inrInfoBx'>
                        <div className='imginrBx'>
                            <img src={reward} alt='' />
                        </div>
                        <strong>
                            {toLocale(referalReward)} HUSD
                        </strong>
                        <p>Referral reward</p>
                    </div>
                </CCol>
            </CRow>
        </CCol>
    )
}
export default Referral;
