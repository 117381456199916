import React, { useEffect, useState } from 'react'
import { CRow, CCol } from '@coreui/react';
import claimedrewards from '../Assets/images/Hashfair Icons/claimed-reward.png'
import unclaimed from '../Assets/images/Hashfair Icons/(v2)unclaimed-rewards.png'
import ammount from '../Assets/images/Hashfair Icons/staked-amount.png'
import { toLocale } from '../hooks/useContract';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {  UserCalling } from '../hooks/useContractData';
import { Pool1, Pool2, Pool3, Pool4, Pool5, Pool6 } from '../config';


export const Statistics = () => {
    const poolData = [
        { pool: Pool1, label: '7 days' },
        { pool: Pool2, label: '30 days' },
        { pool: Pool3, label: '60 days' },
        { pool: Pool4, label: '90 days' },
        { pool: Pool5, label: '180 days' },
        { pool: Pool6, label: '360 days' },
    ];

    const poolsWithStake = poolData.filter(poolData => UserCalling(poolData.pool).stakeAmount > 0);

    const [selectedPool, setSelectedPool] = useState(Pool1);
    const [initialSelection, setInitialSelection] = useState(null);

    const handleChange = (event, newValue) => {
        setSelectedPool(poolsWithStake[newValue].pool); // Update the selected pool based on the clicked tab
    };

    useEffect(() => {
        if (poolsWithStake.length > 0) {
            setInitialSelection(poolsWithStake[0].pool);
        }
    }, [poolsWithStake]);

    useEffect(() => {
        if (initialSelection !== null) {
            setSelectedPool(initialSelection);
        }
    }, [initialSelection]);
    
    // const { claimableReward, claimedReward, stakeAmount } = UserPool(selectedPool);
    const { stakeAmount, claimableReward, claimedReward} = UserCalling(selectedPool)


    return (

        <CCol md="12">
            <div className="tabbar flex justify-content-center">
                {poolsWithStake.length >0 &&
                <Tabs
                    value={poolsWithStake.findIndex(poolData => poolData.pool === selectedPool)}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile="mobile"

                >
                    {poolsWithStake.map((poolData, index) => (
                        <Tab key={index} value={index} label={`${poolData.label} Pool`} />
                    ))}

                </Tabs>}
            </div>
            <CRow xs={{ cols: 1 }} md={{ cols: 3 }} lg={{ cols: 3 }} xl={{ cols: 4 }} className="justify-content-center">

                <CCol sm="auto" className='mb-2'>
                    <div className='inrInfoBx'>
                        <div className='imginrBx'>
                            <img src={claimedrewards} alt='' />
                        </div>
                        <strong>
                            {toLocale(claimedReward)} HUSD
                        </strong>
                        <p>Claimed reward</p>
                    </div>
                </CCol>
                <CCol sm="auto" className='mb-2'>
                    <div className='inrInfoBx'>
                        <div className='imginrBx'>
                            <img src={unclaimed} alt='' />
                        </div>
                        <strong>
                            {toLocale(claimableReward)} HUSD

                        </strong>
                        <p>Unclaimed reward</p>
                    </div>
                </CCol>
                <CCol sm="auto" className='mb-2'>
                    <div className='inrInfoBx'>
                        <div className='imginrBx'>
                            <img src={ammount} alt='' />
                        </div>
                        <strong>
                            {toLocale(stakeAmount)} HFG
                        </strong>
                        <p>Stake amount </p>
                    </div>

                </CCol>
                           </CRow>
        </CCol>
    )
}