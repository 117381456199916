import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Pagination } from "@mui/material";
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { shortAddress, toLocale } from '../hooks/useContract';
import { formatEther } from 'viem';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import CopyToClipboard from 'react-copy-to-clipboard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';


export default function CollapsibleTable(props) {
  const data = props.props

  // Search Filter Data
  const [search, setSearch] = useState();
  const [filteredLocations, setFilteredLocations] = useState(data);

  useEffect(() => {
    if (search) {
      setFilteredLocations(
        data.filter(location =>
          (location.referred ?? '').toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setFilteredLocations(data);
    }
  }, [search, data]);

  const [pagination, setPagination] = useState(0);

  const len = filteredLocations?.length
  const navigate = useNavigate()

  //  Code for pagination 
  const [indexOfLast, setindexOfLast] = useState(10)
  const [indexOfFirst, setindexOfFirst] = useState(0)

  function pagechange(event, value) {
    const currentPage = Number(value)
    const dataPerPage = 10

    const indexOfLastTodo = currentPage * dataPerPage;
    const indexOfFirstTodo = indexOfLastTodo - dataPerPage;

    setindexOfFirst(indexOfFirstTodo)
    setindexOfLast(indexOfLastTodo)
  }
  const pageHandler = () => {
    const PageCount = Math.ceil(len / 10)
    setPagination(PageCount)
  }

  useEffect(() => {
    pageHandler()
  }, [len])


  const notify = () => toast.success("Copy Successfully")
  return (
    <>
      <div className="searchbox flex justify-content-between align-items-center flex-row-reverse">
        <form className="form" onSubmit={(e) => e.preventDefault()}>
          <label htmlFor="search">
            <input
              className="input"
              type="text"
              onChange={(e) => setSearch(e.target.value)}
              required=""
              placeholder="Wallet Address"
              id="search"
              autocomplete="off"
            />
            <div className="fancy-bg" />
            <div className="search">
              <svg
                viewBox="0 0 24 24"
                aria-hidden="true"
                className="r-14j79pv r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-4wgw6l r-f727ji r-bnwqim r-1plcrui r-lrvibr"
              >
                <g>
                  <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z" />
                </g>
              </svg>
            </div>
            <button className="close-btn" type="reset" onClick={()=>setSearch()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </label>
        </form>
        <div className="backsidebtn" onClick={() => navigate("/")}>
          <ArrowBackIcon />
        </div>
      </div>

      <TableContainer component={Paper} sx={{ marginTop: "50px" }}>
        <Table aria-label="collapsible table">
          <TableHead className='incometittle'>
            <TableRow>
              <TableCell >Sr No</TableCell>
              <TableCell align="center">Wallet address</TableCell>
              <TableCell align="center">Level</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Stake Amount</TableCell>
              <TableCell align="center">Pool</TableCell>
              <TableCell align="center">Earning received</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLocations ? filteredLocations.slice(indexOfFirst, indexOfLast).map((row, i) => (
              < >
                <TableRow className='incometable' >

                  <TableCell component="th" scope="row">
                    {i + indexOfFirst + 1}
                  </TableCell>
                  <CopyToClipboard text={row?.referred}>
                    <TableCell align="center" onClick={notify}>
                      <span className='d-flex align-items-center justify-content-center'>
                        {shortAddress(row.referred)}<CopyAllIcon sx={{ width: "17px", marginLeft: "3px" }} />
                      </span>
                    </TableCell>
                  </CopyToClipboard>
                  <TableCell align="center">{row.level}</TableCell>
                  <TableCell align="center" ><div className='statushighlight'>{row.status}</div></TableCell>
                  <TableCell align="center">{formatEther(row.amount)} HFG</TableCell>
                  <TableCell align="center">{(row.pool)} Days</TableCell>
                  <TableCell align="center">{toLocale(formatEther(row.myClaimed))} HUSD</TableCell>

                </TableRow>

              </>
            )) : <></>}

          </TableBody>
        </Table>
      </TableContainer>
      {pagination <= 1 ?
        ''
        : <>
          <Pagination className='myPagination' onChange={pagechange} count={pagination} color="secondary" />
        </>
      }
    </>

  );
}