import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Lottie from 'react-lottie';
import { Buffer } from 'buffer';
import '@coreui/coreui/dist/css/coreui.min.css';
import 'sweetalert2/src/sweetalert2.scss';
import '../src/Assets/css/main.css';
import Layout from './Pages/Layout';
import Home from './Pages/Home';
import loaderIcon from './Assets/images/logo.json';
import BigBangStarField from 'react-big-bang-star-field';
import 'react-toastify/dist/ReactToastify.css';
import { Toaster } from "react-hot-toast";
import { useAccount } from 'wagmi';
import IncomeOverview from './Components/IncomeOverview';


const AppRoutes = () => {

  
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path='/account-overview' element={<IncomeOverview />} />
      </Route>
    </Routes>
  )
}

const App = () => {

  const { address } = useAccount()
  window.Buffer = Buffer

  const loaderOption = {
    loop: true,
    autoplay: true,
    animationData: loaderIcon,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);

  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);

  }, [address]);

  return (
    <>
      {isLoading ? (
        <>
          <div className='loaderMain'>
            <Lottie
              options={loaderOption}
              height={200}
              width={200}
            />
          </div>
        </>
      ) : (
        <>

          <BrowserRouter>
            <AppRoutes  />
          </BrowserRouter>
          <Toaster
            position="bottom-right"
            reverseOrder={false}
          />

          <BigBangStarField
            className="bgBody"
            numStars={150}
            maxStarSpeed={5}
            scale={1}
            starColor="252, 255, 255"
          />
        </>
      )}
    </>
  );
}

export default App;
