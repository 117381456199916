import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { configureChains, createConfig,  WagmiConfig } from 'wagmi'
import { polygon, polygonMumbai } from 'wagmi/chains'
import { createPublicClient, http } from 'viem';
// import {  w3mProvider } from '@web3modal/ethereum'

// const chains = [polygon, polygonMumbai]
// const projectId = '3b5b514734de26a64d34b6d0da911c09'
// const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])

// const wagmiConfig = createConfig({
//   autoConnect: true,
//   connectors: w3mConnectors({ version: 2,projectId, chains }),
//   publicClient
// })


const wagmiConfig = createConfig({
  autoConnect: true,
  publicClient: createPublicClient({
    chain: polygonMumbai,
    transport: http()
  }),
})


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <WagmiConfig config={wagmiConfig}>
        <App />
    </WagmiConfig>
);


reportWebVitals();
