import { CContainer, CButton, CRow, CCol } from "@coreui/react";
import LazyLoad from "react-lazyload";
import { Statistics } from "./Statistics";
import nftLvl7 from "../Assets/images/Hashfair Icons/comp2.gif";
import Referral from "./Referral";
import { HUSD, polygonchainRPC } from "../config";
import { addToken } from "../hooks/addToken";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";


const Banner = () => {
  const navigate = useNavigate()
  const { address } = useAccount()


  const addMainnetToMetaMask = async () => {
    if (typeof window.ethereum === 'undefined') {
      toast.error('Wallet Not Found');
      return;
    }
    try {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: '0x89', // BSC Testnet chainId in hexadecimal
            chainName: 'Polygon',
            nativeCurrency: {
              name: 'MATIC',
              symbol: 'MATIC',
              decimals: 18,
            },
            rpcUrls: [polygonchainRPC],
            blockExplorerUrls: ['https://polygonscan.com/'],
          },
        ],
      });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <section className="banner">
        <CContainer>
          <div className="topInformation text-center">
            <CRow className="align-items-center">
              <CCol lg="6">
                <div className="logo  mx-auto"><b>Engage with your<span > digital assets,</span>  enhance your gaming,<span> and stake</span> HFG</b></div>
                <div className="buttonGroup mt-4">
                  <CButton color="btnMU mx-2 mb-4"
                    onClick={addMainnetToMetaMask}
                  >
                    <span>Add Mainnet</span>
                  </CButton>
                  <CButton color="btnMU mx-2  mb-4"
                    onClick={() => {
                      addToken({
                        data: {
                          address: HUSD,
                          symbol: 'HUSD',
                          decimals: 18,
                          name: "HashFair Games"
                        }
                      })
                    }}
                  >
                    <span>Add HUSD</span>
                  </CButton>
                  <Link style={{textDecoration:"none"}} to={"https://app.uniswap.org/swap?chain=polygon&inputCurrency=0xc2132D05D31c914a87C6611C1[…]e8F&outputCurrency=0x681E04eA8F903da45A6520eE1e5F4B21b4503fcf"} target="_blank">
                  
                  <CButton
                    color="btnMU mx-2 mb-4"
                    
                  >
                    <span>Buy HFG</span>
                  </CButton>
                  </Link>        
                </div>
              </CCol>
              <CCol className="loader">
                <LazyLoad
                  once
                  placeholder={
                    <svg
                      className="loaderAnim"
                      version="1.1"
                      id="L9"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 100 100"
                      enableBackground="new 0 0 0 0"
                    >
                      <path
                        fill="#ffffff"
                        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                      >
                        <animateTransform
                          attributeName="transform"
                          attributeType="XML"
                          type="rotate"
                          dur="1s"
                          from="0 50 50"
                          to="360 50 50"
                          repeatCount="indefinite"
                        />
                      </path>
                    </svg>
                  }
                >
                  <img src={nftLvl7} alt="" />

                </LazyLoad>
              </CCol>
            </CRow>
            <div className="listViewBx ms:mt-5 pt-4">
              <CRow className="align-items-center">
                <Referral />
              </CRow>
            </div>
          </div>

          <div className="listViewBx ms:mt-5 pt-4">
            <CRow className="align-items-center">
              <Statistics />
            </CRow>
          </div>
        </CContainer>
      </section>

      <section className="informationMU">
        <CContainer>
          <CRow className="align-items-center">
            <div className="infotxt">
              <h3> Stake HFG</h3>
              <h4>Boost Your Gaming Experience with Seamless, Secure Digital Asset Integration.</h4>

            </div>
            {address && <div className="account">
              <CButton color="btnMU mx-4 mb-4"
                onClick={() => navigate("/account-overview")}
              >
                <span>Account Overview</span>
              </CButton>
            </div>}


          </CRow>
        </CContainer>
      </section>

    </>
  );
};

export default Banner;
