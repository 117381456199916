import TableContent from "./TableContent";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { useGraphData } from "../hooks/useGraph";
import StakeTable from "./StakeTable";
import TransTable from "./Trans";

export default function IncomeOverview() {
  const [searchTerm, setSearchTerm] = useState("");
  const [value, setValue] = useState(0);
  const { data, load } = useGraphData();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div style={{ marginBottom: "15px" }}>


        <Box
          sx={{
            width: "100%",
            bgcolor: "#000",
            marginBottom: "50px",
            marginTop: "30px",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            centered
            className="hello"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile="mobile"
          >
            <Tab sx={{ color: "#fff" }} label="Referral Income" />
            <Tab sx={{ color: "#fff" }} label="Tx History" />
            <Tab sx={{ color: "#fff" }} label="My Stakes" />
          </Tabs>
        </Box>


        {/* <div className="box">
                <div className="boxborder1">
                    <div>
                        Total Referral
                    </div>
                    <div>
                        12
                    </div>

                </div>
                <div className="boxborder2" >
                    <div>
                        Total Referral
                    </div>
                    <div>
                        12
                    </div>

                </div>
                <div className="boxborder3" >
                    <div>
                        Total Referral
                    </div>
                    <div>
                        12
                    </div>
                </div>
            </div> */}
        {value === 0 && <TableContent props={data.tierDatas} />}
        {value === 1 && <TransTable props={data.transactionHistories} />}
        {value === 2 && <StakeTable props={data.stakeds} />}
        {load === 1 && (
          <div className="text-center p-5" style={{ fontSize: "20px" }}>
            No Record Found
          </div>
        )}
      </div>
    </>
  );
}
