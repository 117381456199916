
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useRef } from "react";
import { useState } from "react";
import { Alldata, Approve, GetReward, Stake } from "../hooks/useContractData";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Pool1, Pool2, Pool3, Pool4, Pool5, Pool6 } from "../config";
import { toLocale } from "../hooks/useContract";
import { toast } from "react-hot-toast"
import { CircularProgress } from "@material-ui/core";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other} >
            {children}
            {onClose ? (
                <IconButton
                    // className="closebtn"
                    aria-label="close"
                    onClick={onClose}
                    style={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}
export default function ModelPOP({ props, onClose }) {
    const selectRef = useRef(null);
    const select2Ref = useRef(null);
    const location = useLocation()

    const [Staked, setStaked] = useState(false);
    const [amount, setAmount] = useState("");
    const [pool, setPool] = useState(Pool1);
    const [refer, setrefer] = useState("");
    const [load, setLoad] = useState(true);


    // Export Functions
    const { execute: getRewardData, Data, percent, day } = GetReward()
    const { execute: ApproveToken } = Approve(pool);
    const { execute: Staking } = Stake(pool);
    const { allowance,HfgBalance } = Alldata(pool);
    const [newStake, setNewStake] = useState(props);


    const RewardCallback = useCallback(async () => {
        const selectedPool = selectRef.current?.value;
        const amount = select2Ref.current?.value;
        try {
            setLoad(false)
            const promist = await getRewardData?.(String(amount), selectedPool);
            setLoad(true)

        } catch (err) {
            setLoad(true)
            toast.error(
                err.reason ? err.reason : err.data ? err.data.message : err.message
            );
        }
    }, [getRewardData]);


    const StakeCallback = useCallback(async () => {
        try {
            const amount = select2Ref.current?.value;
            setStaked(true);
            const promist = await Staking?.(String(amount), String(refer));

            setStaked(false);
            setNewStake(false)
            setAmount("")
        } catch (err) {
            setStaked(false);
            setNewStake(false)
            toast.error(
                err.reason ? err.reason : err.data ? err.data.message : err.message
            );
        }
    }, [refer, Staking]);


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const ReferralId = queryParams.get('referrer');
        setrefer(ReferralId)
    }, [])

    useEffect(() => {
        setNewStake(props)
    }, [props])




    return (<>
        <BootstrapDialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={newStake}
        >
            <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={onClose}
                sx={{ backgroundColor: "#020235", color: "#fff" }}

            >
                <h3>Stake HFG</h3>
            </BootstrapDialogTitle>
            <DialogContent
                dividers
                sx={{ backgroundColor: "#020235", height: 'auto' }}
            >
                <div className="inputvalue2">
                <div className="position-relative dilogwrap">
                    <input className="dilog"
                        ref={select2Ref}
                        type="number"
                        placeholder="Enter Amount"
                        value={amount}
                        onChange={(e) => {
                            setAmount(e.target.value)
                            RewardCallback()
                        }}
                    />
                    <span className="maxbtn" onClick={()=>setAmount(HfgBalance)} >Max</span>
                    </div>
                    <select
                        ref={selectRef}
                        class="form-select2"
                        aria-label="Default select example"
                        placeholder="Select Pool"
                        onChange={(e) => {
                            setPool(e.target.value)
                            RewardCallback()
                        }}
                    >
                        <option selected value={Pool1}>7 Day Pool</option>
                        <option value={Pool2}>30 Day Pool</option>
                        <option value={Pool3}>60 Day Pool</option>
                        <option value={Pool4}>90 Day Pool</option>
                        <option value={Pool5}>180 Day Pool</option>
                        <option value={Pool6}>360 Day Pool</option>
                    </select>
                    <div className="newstake" style={{ textAlign: 'center' }}>
                    <div className="leftinfo">
                      <span className="labeltag">Avl Balance</span>
                      <span> 
                        {toLocale(HfgBalance)} HFG
                        </span>
                    </div>
                        <div className="leftinfo">
                            <span className="labeltag">APR</span>
                            <span> {load ? <>
                                {percent}%
                            </> : <CircularProgress size={"16px"} />} </span>
                        </div>
                        <div className="leftinfo">
                            <span className="labeltag">Total Days</span>
                            <span> {load ?
                                <div style={{ marginRight: "4px" }}>
                                    {day} DAYS
                                </div>
                                : <CircularProgress size={"16px"} />} </span>
                        </div>
                        <div className="leftinfo">
                            <span className="labeltag">Total Reward</span>
                            <span> {load ?
                                <>
                                    {toLocale(Data)} HUSD
                                </>
                                : <CircularProgress size={"16px"} />} </span>
                        </div>
                        <div className="leftinfo">
                            <span className="labeltag">Per Day Rewards</span>
                            <span>
                                {" "}
                                {load ? (Data > 0 && day > 0 ? amount > 0 && Number(toLocale(Data/day)) ===0 ? "less than 0.001":toLocale(Data/day) : 0) : <CircularProgress size={"16px"} />}
                            </span>
                        </div>
                    </div>
                </div>
                <br />
                {allowance > 0 && <div style={{ textAlign: 'center' }}>
                    <button
                        className="boxbtn"
                        disabled={Staked}
                        onClick={() => {
                            StakeCallback()
                            onClose()
                        }}
                    >
                        <span>{Staked ? "Staking..." : "Stake HFG"}</span>
                    </button>
                </div> ||
                    <div style={{ textAlign: 'center' }}>
                        <button className="boxbtn" onClick={ApproveToken}>
                            <span>Approve HFG</span>
                        </button>
                    </div>
                }
                <br />
                <br />
                <p style={{ color: "white" }}>*<b>Note</b> - If you unstake HFG before maturity, 10% of your staked amount will be deducted. Any pending rewards at that time will not be processed. Ensure you wait until maturity to avoid these penalties.</p>

            </DialogContent>
        </BootstrapDialog>
    </>)
}