import React, { useEffect, useState } from "react";
import {
  CButton,
  CHeader,
  CHeaderBrand,
  CContainer,
} from "@coreui/react";
import logo from "../Assets/images/logo.json";
import LazyLoad from "react-lazyload";
import Lottie from "react-lottie";
import { useAccount } from "wagmi";
import { useConnect, useDisconnect, useNetwork, useSwitchNetwork } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { defaultNetwork } from "../config";
import { shortAddress } from "../hooks/useContract";
import SpinCoin from '../Assets/images/spin-logo.gif'
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { toast } from "react-hot-toast"
import imglogo from '../Assets/images/marqueelogo.webp'




const Header = () => {
  const { address } = useAccount()
  const { disconnect } = useDisconnect()
  const { chain } = useNetwork()
  const [hfgPrice, setHfgPrice] = useState(0)

  function getPrice() {
    fetch("https://hfg-price-api.vercel.app/hfg-price")
      .then(response => response.json())
      .then(data => setHfgPrice(data.price))
      .catch(error => console.error('Error fetching data:', error));
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      getPrice()
    }, 12000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    getPrice()
  }, []);


  const { connect } = useConnect({
    connector: new InjectedConnector(),
  })

  const { switchNetworkAsync } = useSwitchNetwork({
    chainId: defaultNetwork
  })

  async function disconnectwallet() {
    localStorage.removeItem('wagmi.injected.shimDisconnect')
    window.localStorage.clear()
    window.dispatchEvent(new Event("storage"));
  }

  const handleConnectWalletClick = () => {
    connect();
    localStorage.setItem('wagmi.injected.shimDisconnect', true);
    if (!window.ethereum) {
      toast.error('No wallet detected!');
    }
  };


  return (
    <>
      <div className="marquee">
        <marquee behavior="scroll" direction="Left">
        <span>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <img
                src={imglogo}
                width={40}
                height={40}
                alt="Hash Fair Logo"
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              Mainnet Web3 Games are live. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <img
                src={imglogo}
                width={40}
                height={40}
                alt="Hash Fair Logo"
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Staking for HFG Coin is Now Live.
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <img
                src={imglogo}
                width={40}
                height={40}
                alt="Hash Fair Logo"
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  HFG Withdrawals Are Now Live on HashFair Games.
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <img
                src={imglogo}
                width={40}
                height={40}
                alt="Hash Fair Logo"
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Hashfair NFT Marketplace is Live
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <img
                src={imglogo}
                width={40}
                height={40}
                alt="Hash Fair Logo"
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
        </marquee>
      </div>
      <header>
        <CHeader>
          <CContainer>
            <div className="  d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
              <CHeaderBrand to="/">
                {" "}
                <div className="header_logo">
                  <a className="sticky_none" href="/">
                    <LazyLoad
                      className="logoMainHead"
                      once
                      placeholder={
                        <svg
                          className="loaderAnim"
                          version="1.1"
                          id="L9"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 100 100"
                          enableBackground="new 0 0 0 0"
                        >
                          <path
                            fill="#ffffff"
                            d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                          >
                            <animateTransform
                              attributeName="transform"
                              attributeType="XML"
                              type="rotate"
                              dur="1s"
                              from="0 50 50"
                              to="360 50 50"
                              repeatCount="indefinite"
                            />
                          </path>
                        </svg>
                      }
                    >
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: logo,
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                          },
                        }}
                      />
                    </LazyLoad>
                  </a>
                </div>
              </CHeaderBrand>
              <div className="d-flex align-items-center " >
                <div>

                </div>
                {address &&
                  <>
                    {chain.id !== defaultNetwork &&
                      <li className="wnbtn" onClick={() => {
                        switchNetworkAsync?.().then(() => { }).catch(() => { })
                      }} >
                        Wrong Network
                      </li>}
                  </>
                }
                <div className="d-flex align-items-center" >
                  <img src={SpinCoin} className="spincoin me-2" alt="" />
                  <p className="my-0 me-3">${Number(hfgPrice) > 0 ? hfgPrice?.substr(0, 5) : 0}</p>
                </div>
                {!address && (
                  <>

                    <button className="boxbtn2" onClick={() => {
                      handleConnectWalletClick()
                    }} >
                      Connect

                    </button>
                  </>
                )}
                {address && (
                  <div className="flex">

                    <CButton className="btnMU" >
                      <span>{shortAddress(address)}</span>
                    </CButton>
                    <CButton className="btnMU" onClick={() => {
                      disconnectwallet()
                      disconnect()
                    }} style={{ marginLeft: '8px' }}>
                      <span className="d-none d-sm-block">Disconnect</span>
                      <ExitToAppIcon />
                    </CButton>
                  </div>
                )}
              </div>
            </div>
            {address &&
              <>
                {chain.id !== defaultNetwork &&
                  <li className="mobilebtn" onClick={() => {
                    switchNetworkAsync?.().then(() => { }).catch(() => { })
                  }} >
                    Wrong Network
                  </li>}
              </>
            }
          </CContainer>
        </CHeader>
      </header>
    </>
  );
};

export default Header;
