import React, {  useState } from "react";
import { toLocale } from "../hooks/useContract";
import { CCol, CRow } from "@coreui/react";
import LazyLoad from "react-lazyload";
import gif from "../Assets/images/Hashfair Icons/HFG Coin.gif";

import {
    ClaimCoin,
    RemoveStake,
    UserCalling,
} from "../hooks/useContractData";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Countdown from "react-countdown";
import { LockingPeriod, Pool1, Pool2, Pool3, Pool4, Pool5, Pool6 } from "../config";
import ModelPOP from "./NewStakeModel";
import Yourstake from "./Yourstake";
import { useAccount } from "wagmi";


function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


const StakeList = () => {
    const { address } = useAccount()
    const [open, setOpen] = useState(false);
    const [newStake, setNewStake] = useState(false);
    const [removePool, setremovePool] = useState(false);
    const { execute: Remove } = RemoveStake(removePool);

    const handleClickOpen = (pool) => {
        setOpen(true);
        setremovePool(pool)
    };
    const handleClose = () => {
        setOpen(false);
    };

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <div>Finished</div>;
        } else {
            return (
                <strong>{`${days}d ${hours}h ${minutes}m ${seconds}s`}</strong>
            );
        }
    };

    const pools = [Pool1, Pool2, Pool3, Pool4, Pool5, Pool6];

    let anyStakeAmountGreaterThanZero = false;



    return (
        <>
            <section className="nftMain" id="nft" >
                <div className="container">
                    <div className="section-headline text-center"></div>
                    <div className="popular_gaming_inner">
                        <CRow className="reasultPageSwiper flex">
                            {pools.map((pool, index) => {
                               
                                // const { TotalReward, stakeAmount, Timer, day, tag } = Alldata(pool);
                                const { stakeAmount, tag, day, Timer, TotalReward } = UserCalling(pool)

                                const { execute: Claim } = ClaimCoin(pool);
                                const { execute: DirectRemove } = RemoveStake(pool);
                                if (Number(stakeAmount) > 0 && address) {
                                    anyStakeAmountGreaterThanZero = true;
                                    return (
                                        <CCol className="cardsec" sm={6} md={5} lg={4} xl={3} key={index} data-index={index} >
                                            <div className="popular_gaming_thumb w-100">
                                                <a href="#!">
                                                    <LazyLoad
                                                        className="nftsvideo"
                                                        once
                                                        placeholder={
                                                            <svg
                                                                className="loaderAnim"
                                                                version="1.1"
                                                                id="L9"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                x="0px"
                                                                y="0px"
                                                                viewBox="0 0 100 100"
                                                                enableBackground="new 0 0 0 0"
                                                            >
                                                                <path
                                                                    fill="#ffffff"
                                                                    d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                                                                >
                                                                    <animateTransform
                                                                        attributeName="transform"
                                                                        attributeType="XML"
                                                                        type="rotate"
                                                                        dur="1s"
                                                                        from="0 50 50"
                                                                        to="360 50 50"
                                                                        repeatCount="indefinite"
                                                                    />
                                                                </path>
                                                            </svg>
                                                        }
                                                    >
                                                        <div className="btn">
                                                            {(tag === 0) ? (
                                                                <li className="wnbtn2">Active Staking Period</li>
                                                            ) : (tag === 1) ? (
                                                                <li className="wnbtn2" style={{ background: "#169d5d" }} >Matured Stake</li>
                                                            ) : (
                                                                <li className="wnbtn2" style={{ background: "#a30606" }}>Maturity Lock</li>
                                                            )}
                                                        </div>

                                                        <div className="py-4 px-2" style={{ height: "150px" }}>
                                                            <div className="leftinfo">
                                                                <span className="labeltag">Pool Days</span>
                                                                {day &&
                                                                    <span className="highlight">{day}</span>}
                                                            </div>
                                                            <div className="leftinfo">
                                                                <span className="labeltag">Stake Amount</span>
                                                                <span>{toLocale(stakeAmount)} HFG</span>
                                                            </div>
                                                            <div className="leftinfo">
                                                                <span className="labeltag">Total Reward</span>
                                                                <span>{toLocale(TotalReward)} HUSD</span>
                                                            </div>
                                                            {((Timer * 1000) > Date.now()) &&
                                                                <div className="leftinfo">
                                                                    <span className="labeltag">
                                                                        Staking Period
                                                                    </span>
                                                                    <span>
                                                                        <Countdown date={Timer * 1000} renderer={renderer} />
                                                                    </span>

                                                                </div>
                                                            }
                                                            {(Timer) * 1000 < Date.now() &&
                                                                <div className="leftinfo">
                                                                    <span className="labeltag">
                                                                        Unlocking Period
                                                                    </span>
                                                                    <span>
                                                                        <Countdown date={(Timer + LockingPeriod) * 1000} renderer={renderer} />
                                                                    </span>
                                                                </div>}
                                                        </div>
                                                        <div className="itembox">
                                                            <div className="d-flex mx-auto">
                                                                <img src={gif} alt="GIF" />
                                                            </div>
                                                        </div>
                                                        <div className="stakebtnarea">

                                                            <>
                                                                <button className="boxbtn" onClick={Claim} disabled={((Timer) * 1000 < Date.now()) ? true : false}>
                                                                    Claim HUSD
                                                                    <span></span>
                                                                </button>

                                                                <button className="boxbtn" onClick={() => setNewStake(true)} >New Stake
                                                                    <span></span>

                                                                </button>
                                                                {(Timer) * 1000 < Date.now() ? <button className="boxbtn" onClick={() => {
                                                                    DirectRemove()
                                                                }} >
                                                                    Remove Stake
                                                                    <span></span>
                                                                </button> :

                                                                    <button className="boxbtn" onClick={() => handleClickOpen(pool)} >
                                                                        Remove Stake
                                                                        <span></span>
                                                                    </button>
                                                                }
                                                                {/* Remove stake popup Start */}
                                                                <Dialog
                                                                    fullScreen={fullScreen}
                                                                    open={open}
                                                                    onClose={handleClose}
                                                                    aria-labelledby="responsive-dialog-title"
                                                                    id={pool}
                                                                >
                                                                    <DialogContent sx={{ background: "#020235" }}>
                                                                        <DialogContentText sx={{ color: "#fff" }} >
                                                                            ALERT: If you unstake HFG before maturity, 10% of your staked amount will be deducted. Any pending rewards at that time will not be processed. Ensure you wait until maturity to avoid these penalties.
                                                                        </DialogContentText>
                                                                    </DialogContent>
                                                                    <DialogActions sx={{ background: "#020235" }}>
                                                                        <Button  style={{ color: "#fff", background: "#e74c3c",padding:"6px 8px",borderRadius:"5px" }} autoFocus onClick={handleClose}>
                                                                            No
                                                                        </Button>
                                                                        <Button style={{ color: "#fff", background: "#3498db",padding:"6px 8px",borderRadius:"5px" }} onClick={() => {
                                                                            Remove(removePool)
                                                                            handleClose()
                                                                        }} autoFocus>
                                                                            Yes
                                                                        </Button>
                                                                    </DialogActions>
                                                                </Dialog>



                                                            </>
                                                            {/* New stake popup Start */}
                                                            <ModelPOP props={newStake} onClose={() => setNewStake(false)} />
                                                            {/* Remove stake popup End */}

                                                        </div>
                                                    </LazyLoad>
                                                </a>
                                            </div>
                                        </CCol>
                                    );
                                }
                                return null;

                            })}
                        </CRow>
                        {!anyStakeAmountGreaterThanZero && <Yourstake />}
                    </div>

                </div>
            </section>
        </>
    );
};

export default StakeList;
